import Analytics from './analytics'

type Props = {
  category: string
  action: string
  name: string
  value?: string | number
}

export const matomoTrackEvent = ({ category, action, name, value }: Props) => {
  Analytics.push(['trackEvent', category, action, name, value])
}

export const matomoTrackUserActivation = ({ id }) => {
  if (!id) return

  matomoTrackEvent({
    category: 'Onboarding - User Activation',
    action: 'Onboarding - User Activated',
    name: `User ID: ${id} activated`,
  })
}
