import React from 'react'
import Cookie from 'universal-cookie'
import { Trans } from 'react-i18next'
import decode from 'jwt-decode'
import { t } from 'i18next'

import { Link } from '@material-ui/core'
import {
  AuthenticationResponseJSON,
  RegistrationResponseJSON,
} from '@simplewebauthn/server/script/deps'
import {
  logIn,
  getMeRequest,
  refresh,
  requestValidation,
  resetPassword,
  signUp,
  verifyEmail,
  changePassword,
  requestOtpCode,
  // verifyOtpCode,
  getMfaState,
  requestMFAReset,
  registerPasskeys,
  loginWithPasskeys,
  activateUser,
  requestActivationOTP,
} from '../../utils/api/auth'
import { openNotification } from '../notification/actions'

import ACTIONS from './constants'
import { AuthFactor } from '../../../enums'
import { SUPPORT_MAIL } from '../../utils/configs'
import { matomoTrackUserActivation } from '../../utils/matomoTrackEvent'

const cookies = new Cookie()

const persistToken = (accessToken: string, refreshToken: string, tokenType = 'Bearer') => {
  const decodedAccessToken = decode(accessToken)
  const decodedRefreshToken = decode(refreshToken)
  cookies.set('access_token', accessToken, {
    path: '/',
    expires: new Date(decodedAccessToken.exp * 1000),
  })
  cookies.set('refresh_token', refreshToken, {
    path: '/',
    expires: new Date(decodedRefreshToken.exp * 1000),
  })
  cookies.set('token_type', tokenType, { path: '/' })
}

export const login = (username, password, mfaParams) => (dispatch) => {
  dispatch({ type: ACTIONS.LOGIN_START })
  return logIn(username, password, mfaParams)
    .then((response) => response.data)
    .then((data) => {
      if (data.success) {
        persistToken(data.access_token, data.refresh_token, data.token_type)
        dispatch({ type: ACTIONS.LOGIN_SUCCESS, data: data.current_user })
      } else {
        throw data
      }
      return data
    })
    .catch((error) => Promise.reject(error))
  // dispatch({
  //   type: ACTIONS_NOTIFY.OPEN_NOTIFICATION,
  //   data: { message: error.response.data.error, type: 'error' },
  // });
}

export const authenticate = (username, password) => (dispatch) => {
  dispatch({ type: ACTIONS.LOGIN_START })
  return logIn(username, password)
    .then((response) => response.data)
    .then((data) => {
      if (!data.success) {
        throw data
      }
      return data
    })
    .catch((error) => {
      dispatch({ type: ACTIONS.LOGIN_ERROR, error: error.message })
      return error
    })
}

export const registration = (userData, entry_query) => (dispatch) => {
  dispatch({ type: ACTIONS.REGISTRATION_START })
  return signUp(userData, entry_query)
    .then((response) => response.data)
    .then((data) => {
      if (data.success) {
        persistToken(data.access_token, data.refresh_token, data.token_type)
        dispatch({ type: ACTIONS.LOGIN_SUCCESS, data: data.current_user })
      } else {
        throw data
      }
      return data
    })
    .catch((error) => {
      dispatch({ type: ACTIONS.REGISTRATION_ERROR, error: error.message })

      throw error
    })
}

export const reset = (user) => (dispatch) =>
  resetPassword(user)
    .then((response) => response.data)
    .then((data) => {
      if (data.success) {
        dispatch(
          openNotification({
            message: <Trans i18nKey="snackbar-message__reset-password_success" />,
          }),
        )
      } else {
        throw data
      }
      return data
    })
    .catch((error) => {
      dispatch(
        openNotification({
          message: <Trans i18nKey={error.data.code} />,
          type: 'error',
        }),
      )
      return error
    })

export const change = (user) => (dispatch) =>
  changePassword(user)
    .then((response) => response.data)
    .then((data) => {
      if (data.success) {
        dispatch(
          openNotification({
            message: <Trans i18nKey="snackbar-message__change-password_success" />,
          }),
        )
      } else {
        throw data
      }
      return data
    })
    .catch((response) => {
      dispatch(
        openNotification({
          message: (
            <Trans
              i18nKey={
                response?.errors
                  ? response.errors[Object.keys(response.errors)[0]]
                  : 'snackbar-message__change-password_error'
              }
            >
              <Link href={`mailto:${SUPPORT_MAIL}`}>{{ contact: SUPPORT_MAIL }}</Link>
            </Trans>
          ),
          type: 'error',
        }),
      )
      throw response
    })

export const getMe = () => (dispatch) => {
  dispatch({ type: ACTIONS.GET_ME_START })
  return getMeRequest()
    .then((response) => response.data)
    .then(async (data) => {
      dispatch({
        type: ACTIONS.GET_ME_SUCCESS,
        payload: data.user,
      })

      return data.user
    })
    .catch((error) => {
      cookies.remove('access_token')
      cookies.remove('refresh_token')
      cookies.remove('token_type')
      dispatch({ type: ACTIONS.GET_ME_ERROR, error })
    })
}

export const verifyRequest = (entry_query: string, email?: string) => (dispatch) =>
  requestValidation(entry_query, email)
    .then(({ data }) => {
      dispatch(
        openNotification({
          message: 'Check your email for verification message and follow the instructions.',
        }),
      )
      return data
    })
    .catch((err) => {
      dispatch(
        openNotification({
          message: err.data.code,
          messageType: 'code',
          type: 'error',
        }),
      )

      throw err.data
    })

export const verifyEmailUser = (code: string, isResetMFA = false) => (dispatch) =>
  verifyEmail(code, isResetMFA)
    .then(({ data }) => {
      if (data.success) {
        if (isResetMFA) {
          return
        }
        persistToken(data.access_token, data.refresh_token, data.token_type)
        dispatch({ type: ACTIONS.LOGIN_SUCCESS, data: data.current_user })
      } else {
        throw data
      }
      // return data
    })
    .catch((err) => {
      dispatch(
        openNotification({
          message: err.data.code,
          messageType: 'code',
          type: 'error',
        }),
      )
      throw err.data
    })

export const refreshToken = (token) => (dispatch) => {
  dispatch({ type: ACTIONS.LOGIN_START, token })
  return refresh(token)
    .then((response) => response.data)
    .then((data) => {
      if (data.success) {
        persistToken(data.access_token, data.refresh_token, data.token_type)
        dispatch({ type: ACTIONS.LOGIN_SUCCESS, data: data.current_user })
      } else {
        throw data
      }
      return data
    })
    .catch((error) => {
      dispatch({ type: ACTIONS.LOGIN_ERROR, error })
      throw error
    })
}

export const actionRequestOtpCode = ({
  login,
  authenticationFactor,
}: {
  login: string
  authenticationFactor: AuthFactor
}) => (dispatch) =>
  requestOtpCode(login, authenticationFactor)
    .then(({ data }) => {
      if (data.success) {
        return data
      }
    })
    .catch((error: { data: { code: string } }) => {
      dispatch(
        openNotification({
          message: error.data.code,
          messageType: 'code',
          type: 'error',
        }),
      )
      throw error
    })

export const actionGetMfaState = (login: string) => () =>
  getMfaState(login)
    .then(({ data }) => {
      if (data.success) {
        return data
      }
    })
    .catch((error) => {
      throw error
    })

export const resetMFA = (user) => (dispatch) =>
  requestMFAReset(user)
    .then((response) => response.data)
    .then((data) => {
      if (data.success) {
        dispatch(
          openNotification({
            message: <Trans i18nKey="snackbar-message__reset-mfa_success" />,
          }),
        )
      } else {
        throw data
      }
      return data
    })
    .catch((error) => {
      dispatch(
        openNotification({
          message: <Trans i18nKey={error.data.code} />,
          type: 'error',
        }),
      )
      return error
    })

export const actionRegisterPasskeys = (
  passkeysResponse: RegistrationResponseJSON,
  userId: string,
  deviceName: string,
) => (dispatch) => {
  dispatch({ type: ACTIONS.REGISTER_PASSKEY_START })
  return registerPasskeys(JSON.stringify(passkeysResponse), userId, deviceName)
    .then((response) => response.data)
    .then((data) => {
      if (!data.success) {
        throw data
      }
      return data
    })
    .catch((error) => Promise.reject(error))
}

export const actionLoginPasskeys = (
  passkeysResponse: AuthenticationResponseJSON,
  challenge,
  mfaParams = {},
) => (dispatch) => {
  dispatch({ type: ACTIONS.LOGIN_PASSKEY_START })
  return loginWithPasskeys(passkeysResponse, challenge, mfaParams)
    .then((response) => response.data)
    .then((data) => {
      if (data.success) {
        persistToken(data.access_token, data.refresh_token, data.token_type)
        dispatch({ type: ACTIONS.LOGIN_PASSKEY_SUCCESS, data: null })
        dispatch({ type: ACTIONS.LOGIN_SUCCESS, data: data.current_user })
      } else {
        throw data
      }
      return data
    })
    .catch((error) => Promise.reject(error))
}

export const actionRequestActivationOTP = (email: string) => (dispatch) =>
  requestActivationOTP({ email })
    .then(({ data }) => {
      if (data.success) {
        dispatch(
          openNotification({
            message: t('dialog__verify-otp_send_successfully'),
            messageType: 'code',
          }),
        )
      }
    })
    .catch((error: { data: { code: string } }) => {
      dispatch(
        openNotification({
          message: t(error.data.code),
          messageType: 'code',
          type: 'error',
        }),
      )
    })

export const actionActivateUser = (email: string, code: string) => (dispatch) => {
  return activateUser({ email, code })
    .then(({ data }) => {
      if (data.success) {
        persistToken(data.access_token, data.refresh_token, data.token_type)
        dispatch({ type: ACTIONS.LOGIN_SUCCESS, data: data.current_user })
        matomoTrackUserActivation({ id: data?.current_user?.id })
      }
      return data
    })
    .catch((error) => {
      throw error
    })
}
